/* eslint-disable react/prop-types */
/**
 * Copyright 2023, IntraLinks, Inc. All rights reserved.
 */

import React, { FC, useState } from 'react';
import {
  IlModalV2, ModalSize, IlLoading, ToastKind
} from 'il-framework-component';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { I18nNamespaces } from '../../../../app/shared/utils/constants.util';
import { IProfileExternalApps } from '../../../../domain/models/IProfileExternalApps';
import { RootState } from '../../../../app/store';
import { deleteExternalApp, getExternalApps } from '../../profileSlice';
import { showToast } from '../../../../app/shared/AppSlice/AppSlice';

interface IRemoveExternalAppModalProp {
    closeModal: () => void;
    selectedExternalApp: IProfileExternalApps;
}

const RemoveExternalAppModal: FC<IRemoveExternalAppModalProp> = ({ closeModal, selectedExternalApp }) => {
  const { t } = useTranslation(I18nNamespaces.PROFILE);
  const userId = useSelector((state: RootState) => state.profile.profileDetails.id);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const externalAppName = selectedExternalApp.extAppName;
  const consentId = selectedExternalApp.id;

  const modalHeading = t('externalApps.modalHeading', { appName: externalAppName });
  const removeExternalAppAccess = async (): Promise<void> => {
    setIsLoading(true);
    try {
      await dispatch(deleteExternalApp(userId as string, consentId));
      dispatch(getExternalApps(userId as string, t));
      dispatch(showToast({
        title: t('externalApps.messages.removeExtApp.success', { ns: I18nNamespaces.PROFILE }),
        timeout: 0 as number,
        kind: ToastKind.Success,
        subtitle: ''
      }));
    } catch (error) {
      dispatch(showToast({
        title: t('externalApps.messages.removeExtApp.error', { ns: I18nNamespaces.PROFILE }),
        timeout: 0 as number,
        kind: ToastKind.Error,
        subtitle: ''
      }));
    }
    setIsLoading(false);
    closeModal();
  };

  const modalBody = (
    <div id='remove-body-container'>
      <p>{t('externalApps.modalBody', { appName: externalAppName })}</p>
    </div>
  );

  return (
    <>
      { isLoading ? <IlLoading /> : (
        <IlModalV2
          dialogBody={modalBody}
          size={ModalSize.xs}
          modalHeading={modalHeading}
          danger
          passiveModal
          open
          onRequestClose={closeModal}
          preventCloseOnClickOutside
          selectorPrimaryFocus='#remove-body-container'
          primaryButtonText={t('externalApps.removeAccess')}
          primaryButtonId='removeAppAccess'
          secondaryButtonText={t('externalApps.cancel')}
          secondaryButtonId='cancelRemoveAppAccess'
          tertiaryButtonText=''
          primaryButtonClick={removeExternalAppAccess}
          secondaryButtonClick={closeModal}
        />
      )}
    </>
  );
};

export default RemoveExternalAppModal;
