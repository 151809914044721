/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * Copyright 2021, IntraLinks, Inc. All rights reserved.
 */

import React, {
  ChangeEvent, FormEvent, useEffect, useRef, useState
} from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  ButtonKind,
  IlButton,
  IlSelect,
  IlSelectItem,
  IlTextInput,
  IlTextInputSize,
  IlToastNotification,
  ToastKind
} from 'il-framework-component';
import { useDispatch, useSelector } from 'react-redux';
import { CountryCode, isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import classNames from 'classnames';

import { I18nNamespaces } from '../../../app/shared/utils/constants.util';
// import { RootState } from '../../../app/store';
import {
  activateFactor,
  enrollFactor,
  resendSms,
  setError,
  setFactorStatus,
  setSelectedFactor,
  setShowMFA,
  setShowSMSForm,
  setValidationError
} from './enrollSlice';

import countries from '../../../app/shared/utils/country-list.json';

import BackButton from '../../../app/shared/components/BackButton/BackButton';

import common from '../../../scss/Common.scss';
import styles from './EnrollMFA.scss';
import { RootState } from '../../../app/store';

const EnrollSMS: React.FC = () => {
  const { t } = useTranslation(I18nNamespaces.PROFILE);

  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryCode, setCountryCode] = useState('US');
  const [smsCode, setSmsCode] = useState('');
  const loading = '';
  const error = '';
  const validationError = useSelector((state: RootState) => state.enroll.validationError);
  const factorStatus: string = useSelector((state: RootState) => state.enroll.factorStatus);
  const mobileNumber: string = useSelector((state: RootState) => state.enroll.phoneNumber);
  const selectedFactor = useSelector((state: RootState) => state.enroll.selectedFactor);
  const resending = '';
  const resendSuccess = '';

  const formattedPhoneNumber = useRef('');

  const dispatch = useDispatch();
  useEffect(() => () => {
    dispatch(setSelectedFactor(undefined));
  }, []);

  const updateCountryCode = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setCountryCode(e.currentTarget.value);
    dispatch(setValidationError(''));
  };

  const updatePhoneNumber = (e: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(setValidationError(''));
    dispatch(setError(''));
    setPhoneNumber(e.currentTarget.value.replace(/[^0-9+ \-.()]|[.\- ()]{2}/g, ''));
  };

  const submitPhoneNumber = (ev: FormEvent | ChangeEvent): void => {
    ev.preventDefault();
    const standardCountryCode = countryCode as CountryCode;
    if (standardCountryCode.toString() === 'Other') {
      dispatch(enrollFactor(t, 'sms', 'phoneNumber', phoneNumber));
    } else if (isValidPhoneNumber(phoneNumber, standardCountryCode)) {
      formattedPhoneNumber.current = parsePhoneNumber(phoneNumber, standardCountryCode).format('E.164');
      dispatch(enrollFactor(t, 'sms', 'phoneNumber', formattedPhoneNumber.current));
    } else {
      dispatch(setValidationError('authentication.errorMessages.INVALID_PHONE_NUMBER'));
    }
  };

  const updateSmsCode = (e: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(setError(''));
    dispatch(setValidationError(''));
    let code = e.currentTarget.value.replace(/[^0-9]/g, '');
    if (code.length > 3) {
      // split into groups of 3 for readability
      code = `${code.slice(0, 3)} ${code.slice(3, 6)}`;
    }
    setSmsCode(code);
  };

  const submitSmsCode = (ev: FormEvent | ChangeEvent): void => {
    ev.preventDefault();
    if (selectedFactor) dispatch(activateFactor(t, selectedFactor.id, smsCode.replace(/[^0-9]/g, '')));
  };

  const resendCodeHandler = (): void => {
    dispatch(resendSms(t, 'sms', mobileNumber));
  };

  const backLinkHandler = (): void => {
    dispatch(setError(''));
    if (factorStatus) {
      // back to phone number screen
      dispatch(setFactorStatus(''));
      dispatch(setValidationError(''));
      dispatch(setError(''));
      setSmsCode('');
    } else {
      dispatch(setShowMFA(true));
      dispatch(setError(''));
      dispatch(setShowSMSForm(false));
    }
  };

  let resendLink = (
    <a
      id='resend-link'
      onClick={resendCodeHandler}
      className={styles.resendLink}
    >
      {t('mfa.SMS_RESEND')}
    </a>
  );

  if (resendSuccess) {
    resendLink = t('mfa.SMS_CODE_SENT');
  } else if (resending) {
    resendLink = t('mfa.SMS_RESENDING');
  }

  const mobileInfo = _.isEmpty(mobileNumber) ? '****'
    : `${mobileNumber.substring(0, 5)}*****${mobileNumber.slice(mobileNumber.length - 4)}`;
  return (
    <div>
      {
        error && (
          <div className={common.toastContainer}>
            <IlToastNotification
              kind={ToastKind.Error}
              title='Error'
              subtitle={t(error)}
              hideCloseButton
              caption=''
            />
          </div>
        )
      }
      <div className={common.ilForm}>
        <BackButton onClick={backLinkHandler} className={styles.backLink} title={t('BACK_TO_PREVIOUS_PAGE')} />

        <h1 id='testSmsFactor' className={classNames(common.formTitle, common.noPadding)}>{t('mfa.USE_SMS')}</h1>

        {
          factorStatus !== 'PENDING_ACTIVATION'
            ? (
              <>
                <p className={classNames(common.helpText, styles.helpText)}>
                  {t('mfa.ENTER_PHONE_NUMBER')}
                </p>

                <form onSubmit={submitPhoneNumber}>
                  <div className={styles.enrollSmsSelectContainer}>
                    <IlSelect
                      labelText={t('mfa.COUNTRY_CODE')}
                      id='country-code'
                      onChange={updateCountryCode}
                      value={countryCode}

                    >
                      {countries.map((country) => (
                        <IlSelectItem
                          key={country.code}
                          value={country.code}
                          text={`${t(country.name, { ns: I18nNamespaces.COUNTRIES_NAMES })} (${country.dial_code})`}
                        />
                      ))}
                      <IlSelectItem
                        key='Other'
                        value='Other'
                        text='Other (International Format)'
                      />
                    </IlSelect>
                  </div>
                  <div className={styles.enrollSmsInputContainer}>
                    <IlTextInput
                      id='phone-number'
                      labelText={t('mfa.PHONE_NUMBER')}
                      placeholder={t('profileInformation.number_placeholder')}
                      type='text'
                      onChange={updatePhoneNumber}
                      invalid={!!validationError}
                      invalidText={t(validationError, { ns: I18nNamespaces.PROFILE })}
                      value={phoneNumber}
                      size={IlTextInputSize.xl}
                    />
                  </div>
                  <div className={common.rightButtonContainer}>
                    <IlButton
                      id='testConfirmButton'
                      className={common.actionButton}
                      role='button'
                      kind={ButtonKind.Primary}
                      onClick={submitPhoneNumber}
                      disabled={loading || phoneNumber.trim() === '' || !!validationError}
                    > {t('confirm')}
                    </IlButton>
                  </div>
                </form>
              </>
            )
            : (
              <>
                <p className={classNames(common.helpText, styles.helpText)}>
                  {t('mfa.SMS_ENTER_CODE', {
                    mobile: mobileInfo
                  })}
                </p>

                <form onSubmit={submitSmsCode}>
                  <div className={styles.verififcationField}>
                    <div className={styles.enrollSmsCodeActive}>
                      <IlTextInput
                        id='verification-code'
                        labelText={t('mfa.VERIFICATION_CODE')}
                        placeholder={t('mfa.SMS_CODE_FORMAT')}
                        type='text'
                        onChange={updateSmsCode}
                        invalid={!!validationError}
                        invalidText={t(validationError, { ns: I18nNamespaces.PROFILE })}
                        value={smsCode}
                        autoFocus
                      />
                    </div>
                    <div className={styles.enrollSmsConfirmBtn}>
                      <IlButton
                        id='testActiveButton'
                        className={common.actionButton}
                        role='button'
                        kind={ButtonKind.Primary}
                        onClick={submitSmsCode}
                        disabled={loading || smsCode.trim().length < 7 || !!validationError}
                      > {t('mfa.ACTIVATE')}
                      </IlButton>
                    </div>
                  </div>
                  <div className={styles.enrollSmsNoCodeLabel}>
                    {t('mfa.SMS_NEED_RESEND')}
                    {' '}
                    {resendLink}
                  </div>
                </form>
              </>
            )
        }
      </div>
    </div>
  );
};

export default EnrollSMS;
