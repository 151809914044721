/**
 * Copyright 2021, IntraLinks, Inc. All rights reserved.
 */
import React, {
  ChangeEvent, FormEvent, useEffect, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  ButtonKind, ButtonSize, ButtonType, IlButton, IlIcon, IlTextInput, IlToastNotification, ToastKind
} from 'il-framework-component';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { I18nNamespaces } from '../../../app/shared/utils/constants.util';
import PasswordStrengthIndicator from '../../../app/shared/components/PasswordStrengthIndicator/PasswordStrengthIndicator';
import { checkComplexity } from '../../../app/shared/utils/helpers.util';
import { setUpdateRequestError, setUpdateRequestSuccess, updatePasswordReq } from './updatePasswordSlice';
import * as Styles from '../../../scss/Common.scss';
import * as styles from './updatePassword.scss';
import { RootState } from '../../../app/store';

const UpdatePassword: React.FC = () => {
  const { t } = useTranslation(I18nNamespaces.PROFILE);
  const dispatch = useDispatch();
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [plainTextPassword, setPlainTextPassword] = useState(false);
  const [currentPasswordConfirm, setCurrentPasswordConfirm] = useState(false);
  const [plainTextPasswordConfirm, setPlainTextPasswordConfirm] = useState(false);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const updateRequestSuccess = useSelector((state: RootState) => state.updatePassword.updateRequestSuccess);
  const updateRequestError = useSelector((state: RootState) => state.updatePassword.updateRequestError);

  const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(setUpdateRequestError(''));
    setPassword(e.currentTarget.value);
    setPasswordsMatch(e.currentTarget.value === passwordConfirm);
  };

  const handleChangePasswordConfirm = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setPasswordConfirm(e.currentTarget.value);
    setPasswordsMatch(e.currentTarget.value === password);
  };

  const handleChangeCurrentPassword = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setCurrentPassword(e.currentTarget.value);
  };

  const onSubmit = async (ev: FormEvent | ChangeEvent): Promise<void> => {
    ev.preventDefault();
    if (!password || !passwordsMatch) {
      return;
    }
    if (currentPassword === password) {
      return;
    }
    setDisableSubmitButton(true);
    await dispatch(updatePasswordReq(currentPassword, passwordConfirm));
    setDisableSubmitButton(false);
  };

  // eslint-disable-next-line prefer-const
  let { score, repeatedCharacters } = checkComplexity(password);

  if (updateRequestError === 'COMMON_PASSWORD') {
    score = 1;
  }

  useEffect(() => (): void => {
    dispatch(setUpdateRequestError(''));
    dispatch(setUpdateRequestSuccess(false));
  }, []);

  const showMatchError = !passwordsMatch && passwordConfirm !== '';
  const newPasswordSameAsOld = password !== '' && currentPassword !== '' && currentPassword === password;

  return (
    <div className={Styles.container}>
      {
          !!updateRequestError && (
          <div className={Styles.toastContainer}>
            <IlToastNotification
              kind={ToastKind.Error}
              title={`${t(`${updateRequestError}`, { ns: I18nNamespaces.PROFILE })}`}
              subtitle=''
              caption=''
            />
          </div>
          )
        }
      {
          updateRequestSuccess && (
          <div className={Styles.toastContainer}>
            <IlToastNotification
              kind={ToastKind.Success}
              title={`${t('recovery.CHANGED_SUCCESSFULLY')}`}
              subtitle=''
              caption=''
              notificationType='toast'
              timeout={4000}
            />
          </div>
          )
      }
      <div className={styles.updateCard}>
        <h1 className={styles.formTitle} id='testUpdateTitle'>{t('recovery.UPDATE_PASSWORD')}</h1>
        <form className={styles.inputFields_Wrapper}>
          <div className={styles.currentPasswordContainer}>
            <div>
              <div className={Styles['il-input-container']}>
                <IlTextInput
                  id='testCurrentPassword'
                  labelText={t('recovery.CURRENT_PASSWORD')}
                  placeholder={t('recovery.CURRENT_PASSWORD')}
                  type={currentPasswordConfirm ? 'text' : 'password'}
                  invalid={false}
                  onChange={handleChangeCurrentPassword}
                  defaultValue=''
                  value={currentPassword}
                  autoFocus
                />
                <div>
                  <IlButton
                    className={Styles['visibility-toggle']}
                    role='button'
                    id='visibleIcon'
                    kind={ButtonKind.Ghost}
                    size={ButtonSize.Small}
                    onClick={(): void => { setCurrentPasswordConfirm(!currentPasswordConfirm); }}
                  >
                    {currentPasswordConfirm ? <IlIcon name='view-blocked' className={Styles['visibility-icon']} />
                      : <IlIcon name='view' className={Styles['visibility-icon']} /> }
                  </IlButton>
                </div>
              </div>
            </div>
          </div>
          <div className={classNames(styles.currentPasswordContainer, styles.profileNewPasswordField)}>
            <div>
              <div className={Styles['il-input-container']}>
                <IlTextInput
                  id='testNewPassword'
                  labelText={t('recovery.NEW_PASSWORD')}
                  placeholder={t('recovery.NEW_PASSWORD')}
                  type={plainTextPassword ? 'text' : 'password'}
                  invalid={newPasswordSameAsOld}
                  invalidText={t('authentication.errorMessages.NEW_PASSWORD_SAME_AS_OLD')}
                  defaultValue=''
                  onChange={handleChangePassword}
                  autoFocus
                />
                <div>
                  <IlButton
                    className={Styles['visibility-toggle']}
                    id='visibleIcon'
                    kind={ButtonKind.Ghost}
                    size={ButtonSize.Small}
                    onClick={(): void => { setPlainTextPassword(!plainTextPassword); }}
                  >
                    {plainTextPassword ? <IlIcon name='view-blocked' className={Styles['visibility-icon']} />
                      : <IlIcon name='view' className={Styles['visibility-icon']} /> }
                  </IlButton>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.indicatorWrapper}>
            <PasswordStrengthIndicator score={score} repeatCharacters={repeatedCharacters} />
          </div>
          <div className={styles.repeatPassword}>
            <div>
              <div className={Styles['il-input-container']}>
                <IlTextInput
                  id='testConfirmPassword'
                  labelText={t('recovery.CONFIRM_PASSWORD')}
                  placeholder={t('recovery.CONFIRM_PASSWORD')}
                  type={plainTextPasswordConfirm ? 'text' : 'password'}
                  invalid={showMatchError}
                  invalidText={t('recovery.PASSWORDS_MISMATCH')}
                  defaultValue=''
                  onChange={handleChangePasswordConfirm}
                />
                <div>
                  <IlButton
                    className={Styles['visibility-toggle']}
                    kind={ButtonKind.Ghost}
                    size={ButtonSize.Small}
                    id='visibleIcon'
                    onClick={(): void => { setPlainTextPasswordConfirm(!plainTextPasswordConfirm); }}
                  >
                    { plainTextPasswordConfirm ? <IlIcon name='view-blocked' className={Styles['visibility-icon']} />
                      : <IlIcon name='view' className={Styles['visibility-icon']} /> }
                  </IlButton>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.updatePasswordContainer}>
            <IlButton
              id='il-next-button'
              role='button'
              type={ButtonType.Submit}
              kind={ButtonKind.Primary}
              onClick={onSubmit}
              disabled={score < 3 || !passwordsMatch || newPasswordSameAsOld || disableSubmitButton}
            >{t('recovery.UPDATE_PASSWORD')}
            </IlButton>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdatePassword;
