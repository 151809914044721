/**
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */

/* eslint-disable no-param-reassign */
import { IlToastNotificationProps, ToastKind } from 'il-framework-component';
// import { ThunkDispatch } from 'redux-thunk';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../store';

export interface IAppState {
 toastDetails: IlToastNotificationProps;
  isAppLoading: boolean;
  orgSelectedFilter: string;
}

interface IToastResponseAction {
  toastProps: IlToastNotificationProps;
}

const initialState: IAppState = {
  toastDetails: {
    title: '', timeout: 1, kind: ToastKind.Error, subtitle: ''
  },
  isAppLoading: false,
  orgSelectedFilter: '1'
};

const AppSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    notificationToast: (state: IAppState, action: PayloadAction<IToastResponseAction>): void => {
      state.toastDetails = action.payload.toastProps;
    },
    setIsLoading: (state: IAppState, action: PayloadAction<boolean>): void => {
      state.isAppLoading = action.payload;
    },
    setOrgSelectedFilter: (state: IAppState, action: PayloadAction<string>): void => {
      state.orgSelectedFilter = action.payload;
    }
  }
});

export const { setIsLoading, setOrgSelectedFilter } = AppSlice.actions;

export const showToast = (toastProps: IlToastNotificationProps): AppThunk => async (dispatch): Promise<void> => {
  const { notificationToast: notificationToastAction } = AppSlice.actions;
  dispatch(notificationToastAction({ toastProps }));
  if (toastProps.timeout === 0) {
    setTimeout(() => {
      dispatch(showToast({
        title: '', timeout: 1, kind: ToastKind.Error, subtitle: ''
      }));
    }, 4000);
  }
};

export default AppSlice.reducer;
