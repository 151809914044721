/**
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */

// /* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../app/store';

export interface HostAppStateProps {
    hideHeaderLogo: boolean;
    hideFooterLogo: boolean;
    hideAppName: boolean;
}

class HostAppState implements HostAppStateProps {
  hideHeaderLogo: boolean;

  hideFooterLogo: boolean;

  hideAppName: boolean;

  constructor(hostProps: HostAppState) {
    const { hideHeaderLogo, hideFooterLogo, hideAppName } = hostProps;
    this.hideAppName = hideAppName;
    this.hideFooterLogo = hideFooterLogo;
    this.hideHeaderLogo = hideHeaderLogo;
  }
}

export interface IHostAppState {
    app: HostAppStateProps;
}

const initialState = {
  app: new HostAppState({
    hideHeaderLogo: true,
    hideFooterLogo: false,
    hideAppName: false
  })
};

export const hostAppSlice = createSlice({
  name: 'hostapp',
  initialState,
  reducers: {
    pathChanged: (state: IHostAppState, action: PayloadAction<IHostAppState>): void => {
      // eslint-disable-next-line no-param-reassign
      state.app = action.payload.app;
    }
  }
});

export const setCoreAppState = (): AppThunk => (dispatch): void => {
  const { pathChanged: pathchanged } = hostAppSlice.actions;
  if (window.location.href.indexOf('/error') > -1) {
    dispatch(pathchanged({
      app: new HostAppState({ hideHeaderLogo: false, hideFooterLogo: true, hideAppName: true })
    }));
  } else dispatch(pathchanged({ app: initialState.app }));
};

export default hostAppSlice.reducer;
