/*
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */

// eslint-disable-next-line import/prefer-default-export
export const ENDPOINT_PREFIX = '/iam'; // e.g. '/myapp'
export const ERROR_PATH_403 = '/errors/403';
export const getErrorPath = (error: string): string => `/errors/${error}`;
export const unauthenticatedPaths = ['/error', '/eula', '/login-consent', '/errors'];

export enum I18nNamespaces {
  DEFAULT = 'default',
  COUNTER = 'counter',
  ADMIN = 'admin',
  POPUP= 'popup',
  ERROR = 'error',
  PROVIDER='provider',
  PROFILE='profile',
  COUNTRIES_NAMES = 'countryNames'
}

export enum AuthenticatorType {
  GOOGLE = 'GOOGLE', OKTA = 'OKTA', INTRALINKS = 'INTRALINKS'
}

export const NUMBER_REGEX = /[^0-9 \-.()]|[.\- ()]{2}/g;

export const SUPPORT_URL = 'https://intralinks.custhelp.com/app/answers/detail_clear/a_id/4034';
export const SECRET_TYPES = {
  CLIENT_ID_SECRET: 'Client Id And Secret Pair',
  PUBLIC_KEY: 'Public Key',
  PUBLIC_PRIVATE_KEY_PAIR: 'Public And Private Key Pair'
};

export enum FileUploadStatus {
  Edit = 'edit',
  Uploading = 'uploading',
  Complete = 'complete'
}

export const EULA_ACTIONS = {
  ACCEPT: 'ACCEPT',
  DECLINE: 'DECLINE'
};

export const CSRF_HEADER_NAME = 'x-il-csrf-token';
type AxiosHeaderMethod =
  | 'get'
  | 'post'
  | 'put'
  | 'patch'
  | 'delete'
  | 'head'
  | 'options';

export const CSRF_INCLUSION_METHODS: AxiosHeaderMethod[] = [
  'post',
  'patch',
  'put',
  'delete'
];

export const ERROR_PATHS = [
  '401',
  '404',
  '500',
  '403',
  '400',
  '405',
  '406',
  '409',
  '415',
  '417',
  '418',
  '502',
  '503',
  'Maintenance', 'maintenance'
];
export const URL_REGEX = '^(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&\'\\(\\)\\*\\+,;=.]+$';
export const EULA_ITEMS: { [key: string]: number } = {
  accessToTheIntralinksService: 7,
  ownershipOfTheIntralinksService: 8,
  confidentiality: 2,
  terminationAccessToService: 2,
  complianceWithLaws: 1,
  limitedWarranty: 4,
  data: 3,
  dataPrivacy: 2,
  disclaimerOfLiability: 3,
  indemnificationRelatingToPostedData: 1,
  exportOfStandaloneUtilities: 1,
  remedy: 1,
  jurisdiction: 1,
  assignment: 1,
  survival: 1,
  miscellaneous: 1,
  changesToThisAgreement: 1,
  notices: 1,
  marketing: 4
};

export const EULA_HREF: { [key: string]: {[key: string]: string } } = {
  dataPrivacy1: { xxherexx: 'https://www.intralinks.com/privacy' },
  marketing1: { xxPrivacyPolicyxx: 'https://www3.intralinks.com/email-preferences' },
  marketing2: { xxherexx: 'https://www.intralinks.com/privacy' }
};
