/**
 * Copyright 2019, IntraLinks, Inc. All rights reserved.
 */
import * as React from 'react';
import {
  Route, Switch, RouteComponentProps, withRouter
} from 'react-router';
import ErrorComponent from '../../../../features/ErrorPages/ErrorComponent';
import ErrorPage from '../../../../features/ErrorPages/ErrorPage';
import ProfileHomeContainer from '../../../../features/Profile/ProfileHome';
import ROUTES from '../../utils/router.util';
import ProfileRoute from '../../../../features/Profile/ProfileRoute';

type IRouteComponentProps = RouteComponentProps;

class RouteComponent extends React.Component<IRouteComponentProps> {
  render(): JSX.Element {
    return (
      <Switch>
        <Route exact path='/' component={ProfileRoute} />
        <Route path='/error' exact component={ErrorComponent} />
        <Route path='/errors' component={ErrorPage} />
        <Route exact path='/profile' component={ProfileRoute} />
        <Route exact path={`${ROUTES.PROFILE_CONTAINER}`} component={ProfileHomeContainer} />
        <Route exact path={`${ROUTES.RECOVERY_SETTINGS_CONTAINER}`} component={ProfileHomeContainer} />
        <Route exact path={`${ROUTES.UPDATE_PASSWORD_CONTAINER}`} component={ProfileHomeContainer} />
        <Route exact path={`${ROUTES.EXTERNAL_APP_ACCESS_CONTAINER}`} component={ProfileHomeContainer} />
      </Switch>
    );
  }
}

export default withRouter(RouteComponent);
