/* eslint-disable react/no-array-index-key */
/**
 * Copyright 2021, IntraLinks, Inc. All rights reserved.
 */

import React, { useEffect, useState } from 'react';
import {
  ButtonKind,
  ButtonSize,
  IlButton,
  IlModalV2,
  IlToastNotification,
  IlTooltipTippy,
  ModalSize,
  ToastKind
} from 'il-framework-component';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { filter, findKey } from 'lodash';
import { AuthenticatorType, I18nNamespaces } from '../../../app/shared/utils/constants.util';
import { RootState } from '../../../app/store';

import common from '../../../scss/Common.scss';
import styles from './EnrollMFA.scss';
import padlockIcon from '../../../assets/padlock.svg';
import successIcon from '../../../assets/success.svg';
import DisableIcon from '../../../assets/disable.svg';
import {
  AvailableFactorProps,
  disableEnrolledFactor,
  ProfileFactor,
  setSelectedFactorAuthenticatorType,
  setSelectedFactorId,
  setSelectedFactorProvider,
  setSelectedFactorType,
  setShowAuthForm,
  setShowMFA,
  setShowPushForm,
  setShowSMSForm
} from './enrollSlice';
import EnrollSMS from './EnrollSMS';
import Authenticator from './Authenticator';
import EnrollPush from './EnrollPush';

const EnrollMFASelect = () => {
  const { t } = useTranslation(I18nNamespaces.PROFILE);
  const dispatch = useDispatch();
  const enrolledFactors = useSelector((state: RootState) => state.enroll.enrolledFactors);
  const availableFactors: AvailableFactorProps[] = useSelector((state: RootState) => state.enroll.availableFactors);
  const supportedFactors: ProfileFactor[] = useSelector((state: RootState) => state.enroll.supportedFactors);
  const error = '';
  const [showModal, setShowModal] = useState(false);
  const showMFA = useSelector((state: RootState) => state.enroll.showMFA);
  const showSMSForm = useSelector((state: RootState) => state.enroll.showSMSForm);
  const showAuthForm = useSelector((state: RootState) => state.enroll.showAuthForm);
  const showPushForm = useSelector((state: RootState) => state.enroll.showPushForm);
  const [selectedFactorIdsToDisable, setSelectedFactorIdsToDisable] = useState<string[]>([]);
  const [factorToDisable, setFactorToDisable] = useState('');
  const [displayedFactors, setDisplayedFactors] = useState<AvailableFactorProps[]>(availableFactors);
  const disabledFactors = useSelector((state: RootState) => state.enroll.disabledFactors);

  useEffect(() => {
    const factors = availableFactors.filter((af) => !!supportedFactors.find((sf) => (af.authnFactor === sf.authnFactor)));
    const isPushFactorEnrolled = !!enrolledFactors.find((ef) => ef.factorType === 'push');
    const isOktaVerifyEnrolled = !!enrolledFactors.find((ef) => ef.authenticatorType === 'OKTA' && ef.factorType === 'token:software:totp');
    if (isPushFactorEnrolled || (!isPushFactorEnrolled && !isOktaVerifyEnrolled)) {
      setDisplayedFactors(factors.filter(
        (af) => af.authenticatorType !== AuthenticatorType.OKTA || af.factorType === 'push'
      ));
    }
    if (!isPushFactorEnrolled && isOktaVerifyEnrolled) {
      setDisplayedFactors(factors.filter(
        (af) => af.factorType !== 'push'
      ));
    }
  }, [enrolledFactors]);
  function getFactor(regFactor: ProfileFactor): string {
    const { factorType, authenticatorType } = regFactor;
    if (factorType === 'sms' || factorType === 'push') {
      return factorType;
    }
    if (authenticatorType === AuthenticatorType.INTRALINKS
        || authenticatorType === AuthenticatorType.OKTA
        || authenticatorType === AuthenticatorType.GOOGLE) {
      return authenticatorType;
    }
    return '';
  }

  const selectFactor = (factor: AvailableFactorProps, regFactor?: ProfileFactor): void => {
    dispatch(setSelectedFactorId(factor.id));
    dispatch(setSelectedFactorAuthenticatorType(factor.authenticatorType));
    if (regFactor) {
      setShowModal(true);
      setSelectedFactorIdsToDisable([regFactor.id]);
      setFactorToDisable(getFactor(regFactor));
      if (regFactor.factorType === 'push') {
        const pairedEnrolledFactor: string = enrolledFactors.find(
          (ef) => ef.factorType === 'token:software:totp' && ef.provider === 'OKTA'
        )?.id || '';
        setSelectedFactorIdsToDisable((arr) => [...arr, pairedEnrolledFactor]);
      }
    } else if (factor.factorType === 'sms') {
      dispatch(setShowMFA(false));
      dispatch(setShowSMSForm(true));
    } else if (factor.factorType === 'push') {
      dispatch(setShowMFA(false));
      dispatch(setShowPushForm(true));
      dispatch(setSelectedFactorType(factor.factorType));
      dispatch(setSelectedFactorProvider(factor.provider));
    } else {
      dispatch(setShowMFA(false));
      dispatch(setShowAuthForm(true));
      dispatch(setSelectedFactorType(factor.factorType));
      dispatch(setSelectedFactorProvider(factor.provider));
    }
  };
  const getAppName = (f: any): string => {
    if (f.factorType === 'push') {
      return 'mfa.push';
    }
    if (f.authenticatorType === AuthenticatorType.INTRALINKS) {
      return 'mfa.INTRALINKS_AUTHENTICATOR';
    }
    return 'mfa.OKTA_VERIFY';
  };

  const getIndicationIcon = (factor: AvailableFactorProps) => {
    const index: string | undefined = findKey(enrolledFactors,
      {
        factorType: factor.factorType,
        provider: factor.provider,
        ...(factor.factorType === 'token:software:totp') && { authenticatorType: factor.authenticatorType }
      });
    // eslint-disable-next-line radix
    const enrolledFact = index ? enrolledFactors[parseInt(index)] : '';
    return enrolledFact
      ? <img src={successIcon} alt={t('mfa.ACTIVATED')} />
      : <img src={DisableIcon} alt={t('mfa.ACTIVATED')} />;
  };

  const isExclusiveFactor = (f: any): boolean => (
    f.provider === 'OKTA'
      && (f.factorType === 'token:software:totp' || f.factorType === 'push')
  );

  const getEnrollBtn = (factor: AvailableFactorProps) => {
    const index: string | undefined = findKey(enrolledFactors,
      {
        factorType: factor.factorType,
        provider: factor.provider,
        ...(factor.factorType === 'token:software:totp') && { authenticatorType: factor.authenticatorType }
      });
    // eslint-disable-next-line radix
    const enrolledFact = index ? enrolledFactors[parseInt(index)] : '';
    // users enrolled factor ids
    const enrolledIds = filter(enrolledFactors, 'id');
    let oppositeFactorToDisable;
    if (!enrolledFact && isExclusiveFactor(factor)) {
      oppositeFactorToDisable = enrolledFactors.find((ef) => isExclusiveFactor(ef));
    }
    if (enrolledFact) {
      const hasPushAndOktaVerifyEnrolled = enrolledFact.factorType === 'push'
          && enrolledFactors.find((f) => f.provider === 'OKTA' && f.factorType === 'token:software:totp')
          && enrolledFactors.length === 2;
      return (
        <IlButton
          kind={ButtonKind.Ghost}
          size={ButtonSize.Small}
          onClick={(): void => selectFactor(factor, enrolledFact)}
          id={`set-${factor.id}`}
          disabled={(enrolledIds && enrolledIds.length === 1) || hasPushAndOktaVerifyEnrolled}
        >
          {t('mfa.DISABLE')}
        </IlButton>
      );
    }
    return (
      <IlTooltipTippy
        disabled={!oppositeFactorToDisable}
        content={(!!oppositeFactorToDisable && t('mfa.DISABLED_MESSAGE', {
          oppositeAuthenticatorType: t(`${getAppName(oppositeFactorToDisable)}`),
          authenticatorType: t(`mfa.${factor.authenticatorType}`)
        }))}
      >
        <span>
          <IlButton
            kind={ButtonKind.Primary}
            size={ButtonSize.Small}
            onClick={(): void => selectFactor(factor)}
            id={`set-${factor.id}`}
            disabled={!!oppositeFactorToDisable}
          >
            {t('mfa.SET_UP')}
          </IlButton>
        </span>
      </IlTooltipTippy>
    );
  };

  const getConfirmModal = () => (
    <div>
      <IlModalV2
        dialogBody={(
          <>
            <p>{t('mfa.confirmDialogBody.REMOVE_AUTHENTICATOR', {
              authApp: t(`mfa.${factorToDisable}`)
            })}
            </p>
            {factorToDisable !== 'sms'
              && (
              <p>{t('mfa.confirmDialogBody.REMOVE_FROM_AUTHENTICATOR_APP',
                { authApp: t(`mfa.${factorToDisable === 'push' ? 'OKTA' : factorToDisable}`) })}
              </p>
              )}
          </>
        )}
        size={ModalSize.sm}
        modalHeading={
          t('mfa.confirmDialogHeader.REMOVE_CONFIRMATION', {
            authApp: t(`mfa.${factorToDisable}`)
          })
        }
        passiveModal
        danger
        open
        onRequestClose={() => {
          setSelectedFactorIdsToDisable([]);
          setShowModal(false);
        }}
        preventCloseOnClickOutside
        selectorPrimaryFocus='#cancelBtn'
        primaryButtonText={t('mfa.DIALOG_DISABLE')}
        secondaryButtonText={t('mfa.DIALOG_CANCEL')}
        secondaryButtonId='cancelBtn'
        primaryButtonId='disableBtn'
        tertiaryButtonText=''
        primaryButtonClick={() => {
          dispatch(disableEnrolledFactor(t, selectedFactorIdsToDisable));
          setSelectedFactorIdsToDisable([]);
          setShowModal(false);
        }}
        secondaryButtonClick={() => {
          setShowModal(false);
          setSelectedFactorIdsToDisable([]);
        }}
        modalBodyV2ClassName='il-framework-component__modal-body'
        modalFooterV2ClassName='il-framework-component__modal-footer'
      />
    </div>
  );

  const getMFAForm = () => (
    <div>
      <div className={common.ilForm}>
        { !disabledFactors ? (
          <div>
            <h1 className={classnames(common.formTitle, styles.title)} id='testAccountSetup'>
              {t('mfa.ACCOUNT_SECURITY_SETUP')}
            </h1>
            <p id='testHelperText' className={classnames(common.helpText)}>
              {t('mfa.HOW_TO_VERIFY')}
            </p>
          </div>
        ) : (
          <div>
            <p>{t('defaultErrorMessage', { ns: I18nNamespaces.PROFILE })}</p>
          </div>
        )}
        { !disabledFactors ? (
          <ul className={styles.factorsList}>
            {displayedFactors.map((factor, index) => (
              <li key={index}>
                <span className={styles.enrolledStatus}>
                  {getIndicationIcon(factor)}
                </span>
                <div className={styles.factorTitleContainer}>
                  <div className={styles.factorTitle}>
                    {t(factor.title)}
                  </div>
                  <div className={styles.securityLevel}>
                    {t('mfa.SECURITY_LEVEL')}:
                    {' '}
                    {[...Array(factor.securityLevel)].map((e, i) => <img key={i} src={padlockIcon} alt='security' />)}
                  </div>
                </div>
                {getEnrollBtn(factor)}
              </li>
            ))}
          </ul>
        ) : ''}
      </div>
      {
            showModal && getConfirmModal()
        }
    </div>
  );

  return (
    <div>
      {
        error && (
          <div className={common.toastContainer}>
            <IlToastNotification
              kind={ToastKind.Error}
              title='Error'
              subtitle={t(error, { ns: I18nNamespaces.PROFILE })}
              hideCloseButton
              caption=''
            />
          </div>
        )
      }
      {showMFA && getMFAForm()}
      {showSMSForm && <EnrollSMS />}
      {showAuthForm && <Authenticator />}
      {showPushForm && <EnrollPush type='Okta' />}
    </div>
  );
};

export default EnrollMFASelect;
