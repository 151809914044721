/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/**
 * Copyright 2021, IntraLinks, Inc. All rights reserved.
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ToastKind } from 'il-framework-component';
import _, { findKey } from 'lodash';
import { TFunction } from 'i18next';
import qrcode from 'qrcode';

import Repos from '../../../infrastructure/repositories/Repos';
import { AppThunk } from '../../../app/store';
import { showToast } from '../../../app/shared/AppSlice/AppSlice';
import { AuthenticatorType, I18nNamespaces } from '../../../app/shared/utils/constants.util';
import {
  ErrorEnumCodes,
  getErrorCode,
  getTranslatedErrorKey
} from '../../../app/shared/utils/errors.util';

// const SMS_RESEND_INTERVAL = 30000; // ms
// let canResendTimeoutId: ReturnType<typeof setTimeout> | null = null;

export interface ProfileFactor {
  id: string;
  factorType: string;
  phone: string;
  provider: string;
  status: string;
  authenticatorType: AuthenticatorType;
  authnFactor: string;
}

export interface EnrollState {
  availableFactors: AvailableFactorProps[];
  supportedFactors: ProfileFactor[];
  enrolledFactors: ProfileFactor[];
  enrolledPendingFactors: ProfileFactor[];
  loadingEnroll: boolean;
  factorStatus: string;
  error: string;
  validationError: string;
  phoneNumber: string;
  resending: boolean;
  resendSuccess: boolean;
  selectedFactorId: string;
  canResend: boolean;
  qrCode: string;
  secretKey: string;
  secretKeyLength: number;
  isQrCodeLoading: boolean;
  selectedFactor?: ProfileFactor;
  showMFA: boolean;
  showSMSForm: boolean;
  showAuthForm: boolean;
  showPushForm: boolean;
  selectedFactorType: string;
  selectedFactorProvider: string;
  selectedFactorAuthenticatorType?: AuthenticatorType;
  disabledFactors: boolean;
}

export interface AvailableFactorProps {
  id: string;
  title: string;
  securityLevel: number;
  factorType: string;
  provider: string;
  authenticatorType?: AuthenticatorType;
  authnFactor: string;
}
export const initialState: EnrollState = {
  availableFactors: [
    {
      id: 'sms',
      title: 'mfa.USE_SMS',
      securityLevel: 2,
      factorType: 'sms',
      provider: 'OKTA',
      authnFactor: 'SMS'
    },
    {
      id: 'OKTA',
      title: 'mfa.USE_IL_AUTH',
      securityLevel: 3,
      factorType: 'token:software:totp',
      provider: 'OKTA',
      authenticatorType: AuthenticatorType.INTRALINKS,
      authnFactor: 'INTRALINKS_AUTHENTICATOR'
    },
    {
      id: 'OKTA_PUSH',
      title: 'mfa.USE_OKTA_PUSH',
      securityLevel: 3,
      factorType: 'push',
      provider: 'OKTA',
      authenticatorType: AuthenticatorType.OKTA,
      authnFactor: 'OKTA_PUSH'
    },
    {
      id: 'OKTA',
      title: 'mfa.USE_OKTA',
      securityLevel: 3,
      factorType: 'token:software:totp',
      provider: 'OKTA',
      authenticatorType: AuthenticatorType.OKTA,
      authnFactor: 'OKTA_VERIFY'
    },
    {
      id: 'GOOGLE',
      title: 'mfa.USE_GAUTH',
      securityLevel: 3,
      factorType: 'token:software:totp',
      provider: 'GOOGLE',
      authenticatorType: AuthenticatorType.GOOGLE,
      authnFactor: 'PREFERRED_AUTHENTICATOR'
    }
  ],
  supportedFactors: [],
  enrolledFactors: [],
  enrolledPendingFactors: [],
  loadingEnroll: false,
  factorStatus: '',
  error: '',
  validationError: '',
  resending: false,
  resendSuccess: false,
  selectedFactorId: '',
  canResend: false,
  phoneNumber: '',
  qrCode: '',
  secretKey: '',
  secretKeyLength: 0,
  isQrCodeLoading: false,
  showMFA: true,
  showSMSForm: false,
  showAuthForm: false,
  showPushForm: false,
  selectedFactorType: '',
  selectedFactorProvider: '',
  selectedFactorAuthenticatorType: AuthenticatorType.OKTA,
  disabledFactors: false
};

export const enrollSlice = createSlice({
  name: 'enroll',
  initialState,
  reducers: {
    addEnrolledFactor: (state: EnrollState, action: PayloadAction<ProfileFactor>): void => {
      const val: string| undefined = findKey(state.enrolledFactors, { factorType: action.payload.factorType, provider: action.payload.provider });
      if (val === undefined) state.enrolledFactors.push(action.payload);
      else state.enrolledFactors[parseInt(val, 10)] = action.payload;
    },
    setSupportedFactors: (state: EnrollState, action: PayloadAction<ProfileFactor[]>): void => {
      state.supportedFactors = action.payload.slice();
      state.enrolledFactors = action.payload.filter((ac) => ac.status === 'ACTIVE');
      state.enrolledPendingFactors = action.payload.filter((ac) => ac.status !== 'ACTIVE' && ac.status !== 'NOT_SETUP');
    },
    addPendingEnrolledFactor: (state: EnrollState, action: PayloadAction<ProfileFactor>): void => {
      const val: string| undefined = findKey(state.enrolledPendingFactors, { factorType: action.payload.factorType, provider: action.payload.provider });
      if (val === undefined) state.enrolledPendingFactors.push(action.payload);
      else state.enrolledPendingFactors[parseInt(val, 10)] = action.payload;
    },
    removePendingEnrolledFactor: (state: EnrollState, action: PayloadAction<string>): void => {
      state.enrolledPendingFactors = state.enrolledPendingFactors.filter((epf) => epf.id !== action.payload);
    },
    removeEnrolledFactor: (state: EnrollState, action: PayloadAction<string>): void => {
      state.enrolledFactors = state.enrolledFactors.filter((ef) => ef.id !== action.payload);
    },
    setLoading: (state: EnrollState, action: PayloadAction<boolean>): void => {
      state.loadingEnroll = action.payload;
    },
    setFactorsDisabled: (state: EnrollState, action: PayloadAction<boolean>): void => {
      state.disabledFactors = action.payload;
    },
    setError: (state: EnrollState, action: PayloadAction<string>): void => {
      state.error = action.payload;
    },
    setValidationError: (state: EnrollState, action: PayloadAction<string>): void => {
      state.validationError = action.payload;
    },
    setFactorStatus: (state: EnrollState, action: PayloadAction<string>): void => {
      state.factorStatus = action.payload;
    },
    setContactNumber: (state: EnrollState, action: PayloadAction<string>): void => {
      state.phoneNumber = action.payload;
    },
    setCanResend: (state: EnrollState, action: PayloadAction<boolean>): void => {
      state.canResend = action.payload;
    },
    setResending: (state: EnrollState, action: PayloadAction<boolean>): void => {
      state.resending = action.payload;
    },
    setResendSuccess: (state: EnrollState, action: PayloadAction<boolean>): void => {
      state.resendSuccess = action.payload;
    },
    setSelectedFactorId: (state: EnrollState, action: PayloadAction<string>): void => {
      const factorId: string = findKey(state.availableFactors, { id: action.payload }) || '';
      state.selectedFactorId = action.payload;
      if (factorId) {
        const factor: AvailableFactorProps = state.availableFactors[parseInt(factorId, 10)];
        const key: string = findKey(state.enrolledPendingFactors, { factorType: factor.factorType, provider: factor.provider }) || '';
        if (key) {
          state.factorStatus = state.enrolledPendingFactors[parseInt(key, 10)].status;
          state.phoneNumber = state.enrolledPendingFactors[parseInt(key, 10)].phone;
          state.selectedFactor = state.enrolledPendingFactors[parseInt(key, 10)];
        }
      }
    },
    setSelectedFactor: (state: EnrollState, action: PayloadAction<ProfileFactor | undefined>): void => {
      state.selectedFactor = action.payload;
    },
    setSelectedFactorAuthenticatorType: (state: EnrollState, action: PayloadAction<AuthenticatorType | undefined>): void => {
      state.selectedFactorAuthenticatorType = action.payload;
    },
    setSelectedFactorProvider: (state: EnrollState, action: PayloadAction<string>): void => {
      state.selectedFactorProvider = action.payload;
    },
    resetEnrollState: (): EnrollState => initialState,
    setQrCode: (state: EnrollState, action: PayloadAction<string>): void => {
      state.qrCode = action.payload;
    },
    setSecretKey: (state: EnrollState, action: PayloadAction<string>): void => {
      state.secretKey = action.payload;
    },
    setSecretKeyLength: (state: EnrollState, action: PayloadAction<number>): void => {
      state.secretKeyLength = action.payload;
    },
    setIsQrCodeLoading: (state: EnrollState, action: PayloadAction<boolean>): void => {
      state.isQrCodeLoading = action.payload;
    },
    setShowMFA: (state: EnrollState, action: PayloadAction<boolean>): void => {
      state.showMFA = action.payload;
    },
    setShowSMSForm: (state: EnrollState, action: PayloadAction<boolean>): void => {
      state.showSMSForm = action.payload;
    },
    setShowAuthForm: (state: EnrollState, action: PayloadAction<boolean>): void => {
      state.showAuthForm = action.payload;
    },
    setShowPushForm: (state: EnrollState, action: PayloadAction<boolean>): void => {
      state.showPushForm = action.payload;
    },
    setSelectedFactorType: (state: EnrollState, action: PayloadAction<string>): void => {
      state.selectedFactorType = action.payload;
    }
  }
});

export const {
  addEnrolledFactor,
  setSupportedFactors,
  removeEnrolledFactor,
  setLoading,
  setFactorStatus,
  setError,
  setValidationError,
  setSelectedFactorId,
  resetEnrollState,
  setIsQrCodeLoading,
  setQrCode,
  addPendingEnrolledFactor,
  removePendingEnrolledFactor,
  setSelectedFactor,
  setShowMFA,
  setShowSMSForm,
  setShowAuthForm,
  setShowPushForm,
  setSecretKey,
  setSecretKeyLength,
  setSelectedFactorType,
  setSelectedFactorProvider,
  setSelectedFactorAuthenticatorType,
  setContactNumber,
  setFactorsDisabled
} = enrollSlice.actions;

export const resetStates = (): AppThunk => (dispatch): void => {
  dispatch(resetEnrollState());
};

export const getSupportedFactors = (): AppThunk => async (dispatch): Promise<void> => {
  dispatch(setLoading(true));
  dispatch(setError(''));
  dispatch(setValidationError(''));
  try {
    const response = await Repos.instance.mfaRepository.getSupportedFactors();
    if (!_.isEmpty(response) && response.status === 200 && response.data?.data?.length > 0) {
      dispatch(resetEnrollState);
      const { data: { data: factors } } = response;
      dispatch(setSupportedFactors(factors));
    }
    dispatch(setLoading(false));
  } catch (error) {
    const translatedErrorKey = getTranslatedErrorKey(error);
    if (translatedErrorKey) {
      await dispatch(setFactorsDisabled(true));
    }
  }
};

export const enrollFactor = (t: TFunction, factorType: string, additionalKey: string, additionalValue: string): AppThunk => async (dispatch): Promise<void> => {
  dispatch(setLoading(true));
  dispatch(setError(''));
  dispatch(setValidationError(''));
  let response;
  // let errorResponse;
  // dispatch(setCanResend(false));
  // if (canResendTimeoutId) {
  //   clearTimeout(canResendTimeoutId);
  //   canResendTimeoutId = null;
  // }
  if (factorType === 'token:software:totp' || factorType === 'push') {
    dispatch(setIsQrCodeLoading(true));
  }
  try {
    response = await Repos.instance.mfaRepository.enrollFactor(factorType, additionalKey, additionalValue);
    if (response.status === 200) {
      dispatch(setSelectedFactor(response.data.data));
      const factorStatusResponse = response?.data?.data?.status;
      const factorId = response?.data?.data?.id;
      if (factorStatusResponse === 'ACTIVE') {
        // dispatch(addEnrolledFactor(factorType));
        dispatch(setShowMFA(true));
        dispatch(addEnrolledFactor(response.data.data));
        dispatch(setFactorStatus('')); // reset
        if (response?.data?.data?.factorType === 'sms') {
          dispatch(setShowSMSForm(false));
        } else {
          dispatch(setShowAuthForm(false));
        }
        dispatch(showToast({
          title: t('mfa.ACTIVATED', { ns: I18nNamespaces.PROFILE }), timeout: 0 as number, kind: ToastKind.Success, subtitle: ''
        }));
      } else if (factorStatusResponse === 'PENDING_ACTIVATION') {
        if (factorType === 'token:software:totp' || factorType === 'push') {
          dispatch(setIsQrCodeLoading(false));
          try {
            const codeDataURL = await qrcode.toDataURL(response?.data?.data?.activation?.qrCode?.customLink);
            dispatch(setQrCode(codeDataURL));
          } catch (err) {
            dispatch(setQrCode(response?.data?.data?.activation?.qrCode?.href));
          }
          const { secretKey, securityCodeLength } = response?.data?.data?.activation;
          if (secretKey && securityCodeLength) {
            dispatch(setSecretKey(secretKey));
            dispatch(setSecretKeyLength(securityCodeLength));
          }
        }
        dispatch(setFactorStatus('PENDING_ACTIVATION'));
        dispatch(setContactNumber(response?.data?.data?.phone));
        dispatch(setSelectedFactorId(factorId));
        // canResendTimeoutId = setTimeout(() => dispatch(setCanResend(true)), SMS_RESEND_INTERVAL);
      } else {
        // not active, not pending?
      //  dispatch(setError('common.GENERIC_SERVER_ERROR'));
        dispatch(showToast({
          title: t('defaultErrorMessage', { ns: I18nNamespaces.PROFILE }),
          timeout: 0 as number,
          kind: ToastKind.Error,
          subtitle: ''
        }));
      }
    }
  } catch (err) {
    if (getErrorCode(err) === ErrorEnumCodes.ExpiredJwt) {
      dispatch(resetStates());
    } else if (getErrorCode(err) === ErrorEnumCodes.InvalidPhoneNumber || getErrorCode(err) === ErrorEnumCodes.PhoneRequired) {
      const translatedErrorKey = getTranslatedErrorKey(err);
      dispatch(setValidationError(translatedErrorKey));
    } else {
      const translatedErrorKey = getTranslatedErrorKey(err);
      dispatch(setError(translatedErrorKey));
      dispatch(showToast({
        title: t(`${translatedErrorKey}`, { ns: I18nNamespaces.ERROR }),
        timeout: 0 as number,
        kind: ToastKind.Error,
        subtitle: ''
      }));
    }
  }

  dispatch(setLoading(false));
};

export const resendSms = (t: TFunction, factorType: string, phoneNumber: string): AppThunk => async (dispatch): Promise<void> => {
  dispatch(setError(''));
  dispatch(setValidationError(''));
  let response;
  try {
    response = await Repos.instance.mfaRepository.enrollFactor(factorType, 'phoneNumber', phoneNumber);
    if (response.status === 200) {
      const factorStatusResponse = response?.data?.data?.status;
      const factorId = response?.data?.data?.id;
      if (factorStatusResponse === 'ACTIVE') {
        // dispatch(addEnrolledFactor(factorType));
        dispatch(setFactorStatus('')); // reset
      } else {
        // dispatch(setResendSuccess(true));
        dispatch(setSelectedFactorId(factorId));
        // setTimeout(() => dispatch(setResendSuccess(false)), SMS_RESEND_INTERVAL);
      }
    }
  } catch (err) {
    if (getErrorCode(err) === ErrorEnumCodes.ExpiredJwt) {
      dispatch(resetStates());
    } else {
      const translatedErrorKey = getTranslatedErrorKey(err);
      dispatch(setError(translatedErrorKey));
      dispatch(showToast({
        title: t(`${translatedErrorKey}`, { ns: I18nNamespaces.PROFILE }),
        timeout: 0 as number,
        kind: ToastKind.Error,
        subtitle: ''
      }));
    }
  }
  // dispatch(setResending(false));
};

export const activateFactor = (t: TFunction, factorId: string, activationCode?: string, authenticatorType?: AuthenticatorType): AppThunk => async (dispatch): Promise<void> => {
  dispatch(setLoading(true));
  dispatch(setError(''));
  dispatch(setValidationError(''));
  let response;
  try {
    response = await Repos.instance.mfaRepository.activateFactor(factorId, activationCode, authenticatorType);
    if (!_.isEmpty(response) && response.status === 200 && response?.data?.data?.status === 'ACTIVE') {
      dispatch(setShowMFA(true));
      dispatch(addEnrolledFactor(response.data.data));
      dispatch(setFactorStatus('')); // reset
      dispatch(removePendingEnrolledFactor(response.data.data.id));
      if (response?.data?.data?.factorType === 'sms') {
        dispatch(setShowSMSForm(false));
      } if (response?.data?.data?.factorType === 'push') {
        dispatch(setShowPushForm(false));
      } else {
        dispatch(setShowAuthForm(false));
      }
      dispatch(showToast({
        title: t('mfa.ACTIVATED', { ns: I18nNamespaces.PROFILE }), timeout: 0 as number, kind: ToastKind.Success, subtitle: ''
      }));
    }
  } catch (err) {
    if (getErrorCode(err) === ErrorEnumCodes.ExpiredJwt) {
      dispatch(resetStates());
      const translatedErrorKey = getTranslatedErrorKey(err);
      dispatch(showToast({
        title: t(`${translatedErrorKey}`, { ns: I18nNamespaces.PROFILE }),
        timeout: 0 as number,
        kind: ToastKind.Error,
        subtitle: ''
      }));
    } else {
      const translatedErrorKey = getTranslatedErrorKey(err);
      dispatch(setValidationError(translatedErrorKey));
      dispatch(showToast({
        title: t(`${translatedErrorKey}`, { ns: I18nNamespaces.PROFILE }),
        timeout: 0 as number,
        kind: ToastKind.Error,
        subtitle: ''
      }));
    }
  }
  dispatch(setLoading(false));
};

export const disableEnrolledFactor = (t: TFunction, ids: string[]): AppThunk => async (dispatch): Promise<void> => {
  dispatch(setValidationError(''));
  try {
    const response = await Repos.instance.mfaRepository.deleteEnrolledFactors(ids[0]);
    if (response?.status === 200) {
      ids.map((i) => dispatch(removeEnrolledFactor(i)));
      dispatch(showToast({
        title: t('successMessages.disabledFactor'), timeout: 0 as number, kind: ToastKind.Success, subtitle: ''
      }));
    }
  } catch (err) {
    const translatedErrorKey = getTranslatedErrorKey(err);
    dispatch(showToast({
      title: t(`${translatedErrorKey}`, { ns: I18nNamespaces.PROFILE }), timeout: 0 as number, kind: ToastKind.Error, subtitle: ''
    }));
  }
};

export default enrollSlice.reducer;
