/*
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
  ButtonKind, ButtonSize, IlButton, IlModalV2, ModalSize
} from 'il-framework-component';
import { connect } from 'react-redux';
import {
  EULA_ACTIONS, EULA_HREF, EULA_ITEMS, I18nNamespaces
} from '../../app/shared/utils/constants.util';
import * as Styles from './Eula.scss';
import Wrapper from '../../app/shared/components/Wrapper/Wrapper';
import { AppDispatch } from '../../app/store';
import eulaAccepted from './EulaSlice';

interface EulaProps extends WithTranslation, IDispatchProps {
}

interface IStateProps {
  hideConfirmModal: boolean;
  btnDisabled: boolean;
}

class Eula extends React.Component<EulaProps, IStateProps> {
  constructor(props: EulaProps) {
    super(props);
    this.state = {
      hideConfirmModal: true,
      btnDisabled: false
    };
  }

  onDeclineClicked = () => {
    this.setState({ hideConfirmModal: false });
  };

  onCloseClicked = () => {
    this.setState({ hideConfirmModal: true });
  };

  async onAccept(action: string) {
    const { dispatchEulaAccepted } = this.props;
    this.setState({ btnDisabled: true });
    await dispatchEulaAccepted(action);
  }

  renderModal = () => {
    const { t } = this.props;
    return (
      <div className={Styles['eula-confirm-modal']}>
        <form>
          <IlModalV2
            dialogBody={t('eula.declineText')}
            size={ModalSize.xs}
            modalHeading={t('eula.confirmTitle')}
            passiveModal
            open
            onRequestClose={() => this.onCloseClicked()}
            preventCloseOnClickOutside
            selectorPrimaryFocus='#eulaGoBackBtn'
            primaryButtonText={t('eula.decline')}
            primaryButtonId='eulaDeclineBtn'
            secondaryButtonText={t('eula.goBack')}
            secondaryButtonId='eulaGoBackBtn'
            tertiaryButtonText=''
            primaryButtonClick={() => this.onAccept(EULA_ACTIONS.DECLINE)}
            secondaryButtonClick={() => this.onCloseClicked()}
          />
        </form>
      </div>
    );
  };

  renderSingleContent = (item: string) => {
    const content = [];
    const { t } = this.props;
    for (let i = 0; i < EULA_ITEMS[item]; i += 1) {
      const eulaTile = `${item}${i + 1}`;
      if (EULA_HREF[eulaTile]) {
        let data = t(`eulaContent.${eulaTile}`);
        const key = Object.keys(EULA_HREF[`${eulaTile}`])[0];
        data = data.replace(key, '');
        content.push(
          <div>
            <div className={Styles['il-eula-content-sub-content']}>{data}
              <a href={EULA_HREF[eulaTile][key]}>{t(`eulahref.${eulaTile}`)}</a>.
            </div>
          </div>
        );
      } else {
        content.push(
          <div className={Styles['il-eula-content-sub-content']}>{t(`eulaContent.${item}${i + 1}`)}</div>
        );
      }
    }
    return content;
  };

  renderContent = () => {
    const { t } = this.props;
    return (
      <div>
        {t('eula.content')}
        {Object.keys(EULA_ITEMS).map((item, val) => (
          <div>
            <div className={Styles['il-eula-content-tiles']}>{val + 1}. {t(`eulaTitle.${item}`)}</div>
            {this.renderSingleContent(item)}
          </div>
        ))}
      </div>
    );
  };

  render() {
    const { t } = this.props;
    const { hideConfirmModal, btnDisabled } = this.state;
    return (
      <div>
        <div>
          <Wrapper>
            <div className={Styles['eula-body']}>
              <div>
                <div>
                  <div className={Styles['eula-header']}>
                    {t('eula.title')}
                  </div>
                  <div />
                </div>
                <div>
                  <div className={Styles['eula-before-block']}>
                    {t('eula.header')}
                  </div>
                  <div className={Styles['eula-agreement-wrapper']}>
                    <div className={Styles['eula-agreement-body']}>
                      {this.renderContent()}
                    </div>
                  </div>
                  <div className={Styles['il-btn-container']}>
                    <div className={Styles.declineBtn}>
                      <IlButton
                        className={Styles.btn}
                        size={ButtonSize.Small}
                        kind={ButtonKind.Primary}
                        onClick={() => this.onDeclineClicked()}
                        disabled={btnDisabled}
                      >{t('eula.decline')}
                      </IlButton>
                    </div>
                    <div className={Styles.acceptBtn}>
                      <IlButton
                        className={Styles.btn}
                        size={ButtonSize.Small}
                        kind={ButtonKind.Primary}
                        onClick={() => this.onAccept(EULA_ACTIONS.ACCEPT)}
                        disabled={btnDisabled}
                      >{t('eula.accept')}
                      </IlButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Wrapper>
        </div>
        {!hideConfirmModal && this.renderModal()}
      </div>
    );
  }
}

interface IDispatchProps {
  dispatchEulaAccepted: (action: string) => void;
}

const mapDispatchToProps = (dispatch: AppDispatch): IDispatchProps => ({
  dispatchEulaAccepted: (action): void => dispatch(
    eulaAccepted(action)
  )
});

export default connect(null, mapDispatchToProps)(withTranslation(I18nNamespaces.DEFAULT)(Eula));
