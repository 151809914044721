import React, {
  ChangeEvent, FormEvent, SyntheticEvent, useEffect, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import {
  ButtonKind, IlButton, IlInlineLoading, IlLoading, IlTextInput, IlTooltipTippy
} from 'il-framework-component';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import { noop } from 'lodash';

import {
  activateFactor,
  enrollFactor,
  setError,
  setFactorStatus,
  setQrCode,
  setSelectedFactor,
  setShowAuthForm,
  setShowMFA,
  setValidationError
} from './enrollSlice';
import { AuthenticatorType, I18nNamespaces } from '../../../app/shared/utils/constants.util';
import common from '../../../scss/Common.scss';
import * as authStyle from './Authenticator.scss';
// import * as Styles from '../../../app/shared/VerifyIdentity/VerifyIdentity.scss';
import QrCode from '../../../app/shared/components/QrCode';
import CopyIcon from '../../../app/shared/components/CopyIcon';
import BackButton from '../../../app/shared/components/BackButton/BackButton';
import { RootState } from '../../../app/store';
import googlePlayBadge from '../../../assets/google-play-badge.svg';
import appleStoreBadge from '../../../assets/apple-store-badge.svg';

export enum AuthenticatorEnum {
  PreferredAuth = 'GOOGLE',
  OktaAuth = 'OKTA'
}

let timeout: ReturnType<typeof setTimeout>;

const Authenticator: React.FC = (): React.ReactElement => {
  const { t } = useTranslation(I18nNamespaces.PROFILE);
  const dispatch = useDispatch();
  const loading = useSelector((state: RootState) => state.enroll.loadingEnroll);
  const selectedFactorId = useSelector((state: RootState) => state.enroll.selectedFactorId);
  const validationError = useSelector((state: RootState) => state.enroll.validationError);
  const qrCode = useSelector((state: RootState) => state.enroll.qrCode);
  const secretKey = useSelector((state: RootState) => state.enroll.secretKey);
  const secretKeyLength = useSelector((state: RootState) => state.enroll.secretKeyLength);
  const isQrCodeLoading = useSelector((state: RootState) => state.enroll.isQrCodeLoading);
  const factorStatus = useSelector((state: RootState) => state.enroll.factorStatus);
  const selectedFactorProvider = useSelector((state: RootState) => state.enroll.selectedFactorProvider);
  const selectedFactorAuthenticatorType = useSelector((state: RootState) => state.enroll.selectedFactorAuthenticatorType);

  const isOktaVerify = selectedFactorAuthenticatorType === AuthenticatorType.OKTA;
  useEffect(() => {
    dispatch(enrollFactor(t, 'token:software:totp', 'provider', selectedFactorProvider));
    return () => {
      dispatch(setQrCode(''));
      dispatch(setSelectedFactor(undefined));
    };
  }, []);
  const [code, setCode] = useState('');
  const [isImageLoading, setIsImageLoading] = useState(true);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const onImageLoad = (event: SyntheticEvent<HTMLImageElement, Event>) => {
    setIsImageLoading(false);
    event.preventDefault();
  };
  const tooltipClickHandler = (): void => {
    setIsTooltipVisible(true);
    clearTimeout(timeout);
    timeout = global.setTimeout(() => setIsTooltipVisible(false), 3000);
  };
  const updateCode = (e: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(setError(''));
    dispatch(setValidationError(''));
    const input = e.currentTarget.value.replace(/[^0-9]/g, '');
    if (input.length <= secretKeyLength + 1) {
      if (input.length > 3) {
        // split into groups of 3 for readability
        setCode(`${input.slice(0, 3)} ${input.slice(3, 6)}`);
      } else {
        setCode(input);
      }
    }
  };
  const submit = (ev: FormEvent | ChangeEvent): void => {
    ev.preventDefault();
    dispatch(activateFactor(t, selectedFactorId, code.replace(/[^0-9]/g, ''), selectedFactorAuthenticatorType));
  };
  const backLinkHandler = (): void => {
    dispatch(setError(''));
    dispatch(setQrCode(''));
    if (factorStatus) {
      dispatch(setFactorStatus(''));
      dispatch(setValidationError(''));
      dispatch(setError(''));
    }
    dispatch(setShowMFA(true));
    dispatch(setShowAuthForm(false));
    // history.push('/enroll');
  };

  return (
    <div>
      <div className={common.ilForm}>
        <BackButton title={t('BACK_TO_PREVIOUS_PAGE')} onClick={backLinkHandler} className={authStyle.backLink} />
        {selectedFactorAuthenticatorType === AuthenticatorType.GOOGLE
          && (
          <>
            <h1 id='testFactorTitle' className={classnames(common.formTitle, common.noPadding)}>
              {t('mfa.Title_PreferredAuth')}
            </h1>
            <p id='testFactorHelperText' className={classnames(common.helpText, authStyle.helpText)}>
              {t('mfa.AUTHENTICATOR_HELP')}
            </p>
            <div className={authStyle.helpTextOptions}>
              <span className={authStyle.authOption}>{t('mfa.Authenticator_Microsoft')}</span>
              <span className={authStyle.authOption}>{t('mfa.Authenticator_Google')}</span>
              <span className={authStyle.authOption}>{t('mfa.Authenticator_oneLogin')}</span>
              <span className={authStyle.authOption}>{t('mfa.Authenticator_Authy')}</span>
              <span className={authStyle.authOption}>{t('mfa.Authenticator_LastPass')}</span>
            </div>
          </>
          )}
        {(selectedFactorAuthenticatorType === AuthenticatorType.INTRALINKS || selectedFactorAuthenticatorType === AuthenticatorType.OKTA)
        && (
          <>
            <h1 className={classnames(common.formTitle, common.noPadding)} id='testAuthenticateTitle'>
              {t(isOktaVerify ? 'mfa.TITLE_OKTA_AUTH' : 'mfa.USE_IL_AUTH')}
            </h1>
            <p className={classnames(common.helpText, authStyle.helpText)} id='testAuthHelpText'>
              {t(isOktaVerify ? 'mfa.AUTHENTICATOR_HELP_OKTA' : 'mfa.INTRALINKS_AUTHENTICATOR_ENROLL_HELP')}
            </p>
          </>
        )}
        <div className={authStyle.qrContainer}>
          {
            isImageLoading ? <IlLoading className={authStyle.loader} withOverlay={false} /> : null
          }
          <QrCode onLoad={onImageLoad} hide={isImageLoading} source={qrCode} alt='Qr code' />
        </div>
        {
          isQrCodeLoading && isImageLoading ? null : (
            <div className={authStyle.container}>
              <p className={classnames(authStyle.text, authStyle.authSecureKeyLabel)}>
                {t('mfa.SECRET_CODE')}

              </p>
              <p id='secret-key' className={classnames(authStyle.text, authStyle.leftMargin)}>
                {secretKey}
              </p>
              <div className={authStyle.leftMargin}>
                <CopyToClipboard text={secretKey}>
                  <div>
                    <IlTooltipTippy visible={isTooltipVisible} content={t('mfa.COPIED_SUCCESS')} trigger='click'>
                      <span className={authStyle.blockSpan}>
                        <IlButton onClick={tooltipClickHandler} kind={ButtonKind.Ghost} className={classnames(authStyle.copyButton)}>
                          <CopyIcon />
                        </IlButton>
                      </span>
                    </IlTooltipTippy>
                  </div>
                </CopyToClipboard>
              </div>
            </div>
          )
        }
        { selectedFactorAuthenticatorType === AuthenticatorType.INTRALINKS && (
        <div className={authStyle.badgeContainer}>
          <a target='_blank' href='https://play.google.com/store/apps/details?id=com.intralinks.sm' rel='noreferrer'>
            <img src={googlePlayBadge} className={authStyle.badge} alt='Google Play' />
          </a>
          <a target='_blank' href='https://apps.apple.com/us/app/intralinks/id1129842754' rel='noreferrer'>
            <img src={appleStoreBadge} className={authStyle.badge} alt='Apple Store' />
          </a>
        </div>
        )}
        <div className={authStyle.break} />
        <p className={classnames(common.helpText, common.noPadding, authStyle.helpText)}>
          {t('mfa.AUTHENTICATOR_VERIFY', {
            digits: secretKeyLength
          })}
        </p>
        <div className={authStyle.authInputContainer}>
          <IlTextInput
            id='verification-code'
            labelText={t('mfa.VERIFICATION_CODE')}
            placeholder={t('mfa.CODE', {
              digits: secretKeyLength
            })}
            type='text'
            invalid={!!validationError}
            invalidText={t(validationError, { ns: I18nNamespaces.PROFILE })}
            value={code}
          // disabled={loading}
            onChange={updateCode}
            autoFocus
          />
        </div>
        <div className={authStyle.activationContainer}>
          <IlButton
            // className={Styles.actionButton}
            id='testActiveButton'
            role='button'
            kind={ButtonKind.Primary}
            onClick={submit}
            disabled={loading || code.trim().length - 1 !== secretKeyLength || !!validationError}
          > {t('mfa.ACTIVATE')}
          </IlButton>
        </div>
        { loading && !isQrCodeLoading && (
          <div className={common.loadingContainer}>
            <IlInlineLoading description={t('mfa.login.PLEASE_WAIT')} success={false} onSuccess={noop} />
          </div>
        ) }
      </div>
    </div>
  );
};

export default Authenticator;
