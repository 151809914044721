/**
 * Copyright 2020, IntraLinks, Inc. All rights reserved.
 */

import { AppThunk } from '../../app/store';
import Repos from '../../infrastructure/repositories/Repos';

const eulaAccepted = (action: string): AppThunk => async (): Promise<void> => {
  await Repos.instance.eulRepository.createWithRedirect({ action }, undefined);
};

export default eulaAccepted;
